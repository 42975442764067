
import { defineComponent } from "vue";
import { ElMessage } from "element-plus";
// import { getAuth, onAuthStateChanged } from "firebase/auth";
// import { getApp } from "firebase/app";
// import {
//   getStorage,
//   ref,
//   uploadString,
//   // uploadBytesResumable,
// } from "firebase/storage";
// import _ from "lodash";
import helpers from "@/helpers/global";

export default defineComponent({
  name: "CreateAccount",
  created() {
    if (localStorage.getItem("userData")) {
      this.$router.replace("/");
      return;
    }
    /*
          const auth = getAuth();
          onAuthStateChanged(auth, (user) => {
            if (user) {
              // User is signed in, see docs for a list of available properties
              // https://firebase.google.com/docs/reference/js/firebase.User
              const userData = localStorage.getItem("userData");
              if (!userData) {
                auth.signOut();
                this.$store.commit("auth/setUserLogin", null, { root: true });
              }
              this.$router.replace("/");
            } else {
              localStorage.removeItem("userData");
            }
          });
          //*/
    this.$store.dispatch("auth/initSignupFormDetails", null, { root: true });
    this.$store.dispatch("forceReady", null, { root: true });
  },
  watch: {
    error(value) {
      ElMessage.error(value);
      console.error(value);
    },
    userLogin(value) {
      if (value) {
        this.$router.push("/");
      }
    },
    lang(value: string) {
      if (value == "en") {
        (this as any).$i18n.setLocale("en");
        this.lang = "en";
        this.form.lang = "en";
      } else if (value == "th") {
        (this as any).$i18n.setLocale("th");
        this.lang = "th";
        this.form.lang = "th";
      }
    },
  },
  computed: {
    userLogin(): any {
      return this.$store.getters.getLoginStatus;
    },
    error(): any {
      return this.$store.getters["auth/error"];
    },
    // form() {
    //   return this.$store.getters["CreateAccountForm/formValues"];
    // },
  },
  data() {
    return {
      lang: "en",
      form: {
        lang: "en",
        firstName: "",
        lastName: "",
        companyName: "",
        jopTitle: "",
        hiresPerYears: "",
      },
      hiresPerYearsOptional: [
        "10 - 49",
        "50 - 199",
        "200 - 499",
        "500 - 999",
        "more than 1,000",
      ] as any,
      formValidate: {
        lang: true,
        firstName: false,
        lastName: false,
        companyName: false,
        jopTitle: false,
        hiresPerYears: false,
      },
    };
  },
  mounted() {
    if (this.userLogin) {
      console.log("Your has been login!");
      this.$router.push("/");
    }
    const lang = localStorage.getItem("lang");
    if (lang == "EN") {
      (this as any).$i18n.setLocale("en");
      this.lang = "en";
    } else if (lang == "TH") {
      this.lang = "th";
    }

    if (this.lang == "th") {
      this.hiresPerYearsOptional = [
        "10 - 49",
        "50 - 199",
        "200 - 499",
        "500 - 999",
        (this as any).$i18n.t("createAccount.more_than") + " 1,000",
      ];
    }
  },
  methods: {
    validateStyle(status: boolean) {
      if (status) {
        return "validate-true";
      }
      return "validate-false";
    },
    createFreeDetail() {
      if (this.form.lang == "TH") {
        (this as any).$i18n.setLocale("th");
        localStorage.setItem("lang", "TH");
      } else {
        (this as any).$i18n.setLocale("en");
      }
      let validate = true;
      this.formValidate.jopTitle = false;
      this.formValidate.hiresPerYears = false;
      this.formValidate.companyName = false;
      this.formValidate.lastName = false;
      this.formValidate.firstName = false;
      if (!this.form.firstName) {
        this.formValidate.firstName = true;
        validate = false;
      }
      if (!this.form.lastName) {
        this.formValidate.lastName = true;
        validate = false;
      }
      if (!this.form.companyName) {
        this.formValidate.companyName = true;
        validate = false;
      }
      if (
        !this.form.hiresPerYears ||
        this.form.hiresPerYears == "Number of employees"
      ) {
        this.formValidate.hiresPerYears = true;
        validate = false;
      }
      if (!this.form.jopTitle) {
        this.formValidate.jopTitle = true;
        validate = false;
      }
      if (validate) {
        // @see views\MyAccount\MyProfile.vue
        const lang = this.form.lang;
        const setLocale = (lang: string) => {
          (this as any).$i18n.setLocale(lang);
        };
        setLocale(lang);
        localStorage.setItem("lang", lang.toUpperCase());

        this.$store.dispatch("auth/setSignupInfo", this.form, { root: true });

        const referralCode = helpers.getReferralCode();
        if (referralCode) {
          this.$router.push("/signup/branding?" + referralCode);
        } else {
          this.$router.push({ name: "SignupBranding" });
        }

        // @deprecated no longer use
        //
        // this.$store.dispatch(
        //   "CreateAccountForm/setCreateStep",
        //   "create_look_feel",
        //   { root: true }
        // );
      }
    },
  },
});
